import React from 'react';
import withTranslations from '../../components/hoc/withTranslations';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { FaCalendarAlt, FaClock, FaCodeBranch, FaDollarSign, FaMapMarkerAlt } from 'react-icons/fa';
import CourseTrait from '../../components/courses/CourseTrait';
import HeaderBar from '../../components/courses/HeaderBar';
import styles from '../../styles/courses/courseimage.module.css';

const GitCourse = () => (
  <Layout>
    <Seo
      title='Taller Git'
      description={
        'Git es una herramienta que se utiliza para controlar las versiones de un proyecto de software. Facilita\n' +
        ' el proceso de desarrollo permitiéndote volver a un estado funcional en cualquier momento y habilitando que\n' +
        ' distintos integrantes de un equipo puedan trabajar simultáneamente.'
      }
      keywords={[`git`, `workshop`, `desktop`]}
    />

    <FaCodeBranch size='8em' />
    <br />
    <br />

    <h1>Taller de Git</h1>

    <p>
      Git es una herramienta que se utiliza para controlar las versiones de un proyecto de software. Facilita el proceso
      de desarrollo permitiéndote volver a un estado funcional en cualquier momento y habilitando que distintos
      integrantes de un equipo puedan trabajar simultáneamente.
    </p>

    <p>
      <b>Inicio: </b> Próximamente
    </p>

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 50
      }}
    >
      <CourseTrait icon={<FaCalendarAlt color='#FFA500' size='4em' />}>Sábados 9-13</CourseTrait>
      <CourseTrait icon={<FaClock color='#FFA500' size='4em' />}>8 horas</CourseTrait>
      <CourseTrait icon={<FaMapMarkerAlt color='#FFA500' size='4em' />}>Pendiente - ITS</CourseTrait>
      <CourseTrait icon={<FaDollarSign color='#FFA500' size='4em' />}>120 MXN</CourseTrait>
    </div>

    <HeaderBar>¿Por qué Git?</HeaderBar>
    <ul>
      <li>
        <b>Escalable</b>: Git es realmente escalable por lo que no varía si el proyecto en el que estés trabajando va en
        crecimiento. Puedes comenzar rápidamente pequeños proyectos y escalar hasta proyectos muy grandes.
      </li>
      <li>
        <b>Popular</b>: todas las empresas de software utilizan un sistema de control de versiones, y entre ellos Git es
        el más utilizado.{' '}
        <a
          target='_blank'
          href='https://trends.google.com/trends/explore?q=%2Fm%2F05vqwg,%2Fm%2F08441_,%2Fm%2F012ct9'
          rel='noopener noreferrer'
        >
          <u>Ver comparación actual aquí</u>
        </a>
        <div className={styles.imageContainer}>
          <img
            src='https://dc722jrlp2zu8.cloudfront.net/media/uploads/2018/11/15/estadisticagit.png'
            alt=''
            className={styles.sampleImage}
          />
        </div>
      </li>
      <li>
        <b>Velocidad</b>: las operaciones de Git son rápidas, ya que gran parte está escrito en lenguaje C y se pueden
        hacer en local fuera de línea, es decir, sin estar conectados a una red, lo que significa que es increíblemente
        rápido.
      </li>
      <li>
        <b>Trabajo colaborativo</b>: distintos desarrolladores pueden trabajar sobre un mismo repositorio y sus cambios
        se verán reflejados en el producto final, sin importar en qué parte del mundo se encuentren.
      </li>
      <li>
        <b>Branches</b>: los programadores pueden trabajar en una característica particular sin interferir en el código
        base principal, ya que las ramas son proyectos individuales dentro de un mismo repositorio.
      </li>
      <li>
        <b>Distribuido</b>: cada desarrollador tiene una copia del repositorio de origen en su máquina de desarrollo.
      </li>
      <li>
        Git es software libre y <b>open source</b>.
      </li>
    </ul>

    <HeaderBar>Lo que aprenderás</HeaderBar>
    <p>
      Al finalizar el curso, podrás desarrollar proyectos en equipo de manera profesional. Versionarás tus trabajos para
      mantener un historial ordenado de todos los cambios.
    </p>

    <HeaderBar>Contenido</HeaderBar>
    <p>
      <b>Módulo 1 </b>Introducción
    </p>
    <p>
      <b>Módulo 2 </b>Git basics
    </p>
    <p>
      <b>Módulo 3 </b>Git branching
    </p>
    <p>
      <b>Módulo 4 </b>Git contributing
    </p>
    <p>
      <b>Módulo 5 </b>Tools
    </p>

    <HeaderBar>Requerimientos</HeaderBar>
    <ul>
      <li>Laptop</li>
      <li>Muchas ganas de aprender</li>
    </ul>

    <HeaderBar>Instructores</HeaderBar>
    <p>
      <b>Alex Vilchis y Fernando Pinedo</b>
    </p>
    <p>
      Desarrolladores de <a href='https://haztuhorario.com'>haztuhorario.com</a>. Han colaborado en múltiples proyectos
      con Git. Tienen experiencia en la industria manufacturera, creando software especializado capaz de resolver
      problemáticas y generar ahorros económicos para la empresa.
    </p>

    {/* <EnrollmentForm
      title='¡Separa tu lugar!'
      formName='newsletter'
      text='Sé el primero en enterarte de nuevos cursos y eventos.'
    />
     */}
    <style jsx>{`
      b {
        margin-right: 5px;
      }
    `}</style>
  </Layout>
);

export default withTranslations()(GitCourse);
